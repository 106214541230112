.filters_block {
  height: rem(100);
  margin-left: 1rem;
  margin-right: 1rem;
  display: grid;
  grid-template-columns: 2fr 2fr 3fr 3fr 3fr 2fr 3fr 1fr 5fr 5fr 3fr 4fr 10rem;
  background: linear-gradient(180deg, #E1E1E1 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
  border: 1px solid #B0B0B0;

  .filter {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 rem(5);

    &_title {
      margin-top: 1rem;
      font-size: 0.8rem;
    }

    &_inputs {
      margin-top: 0.2rem;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      input, select {
        width: 100%;
        height: rem(22);
        font-size: 0.8rem;
      }

      input::-webkit-input-placeholder {
        color: inherit;
      }

      & > * {
        margin-bottom: 0.3rem;
      }

      &_datepicker_box {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        height: 100%;
        transition: 0.2s;

        label {
          width: 100%;
          height: rem(22);
          font-size: 0.8rem;
          padding: 0.2rem 0.2rem 0.2rem 0.3rem;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          background-color: #FFFFFF;
        }
      }

      &_datepicker {
        margin: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        box-sizing: border-box;
      }

      &_datepicker::-webkit-calendar-picker-indicator {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        cursor: pointer;
      }

      .search_filter_dropdown {
        position: absolute;
        top: 65%;
        width: 100%;
        height: max-content;
        max-height: rem(500);
        padding: 1rem 0.5rem;
        background-color: white;
        border-radius: 0 0 5px 5px;
        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
        overflow: auto;
        z-index: 20;

        &_option {
          padding: 0.3rem;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          text-align: left;
          cursor: pointer;
          font-size: 0.75rem;
          overflow: hidden;

          &:hover {
            background-color: lavender;
          }

          span {
            white-space: nowrap;
          }
        }
      }
    }
  }
}
