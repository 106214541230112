@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    @apply leading-tight
  }
}

@layer components {
  .btn__outlined {
    @apply relative py-[1.25rem] outlined_gradient;
  }

  .btn__outlined::before {
    @apply rounded-2xl;
  }

  .outlined_gradient {
    position: relative;
  }

  .outlined_gradient::before {
    content: "";
    position: absolute;
    inset: 0;
    padding: 2px;
    border: 2px solid #F79298;
    /*// todo: fix mask*/
    /*//background: linear-gradient(180deg, #F79F2D 0%, #E44E98 100%);*/
    /*//-webkit-mask: linear-gradient(#fff, #fff) content-box, linear-gradient(#fff, #fff);*/
    /*//-webkit-mask-composite: xor;*/
    /*//mask-composite: exclude;*/
    pointer-events: none;
  }

  .bg-gradient {
    @apply bg-gradient-to-r from-[#FF4D4D] to-[#FF971D]
  }
}
