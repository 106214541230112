.paginator {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &:hover > div {
    transform: translateY(0);
    opacity: 1;
  }

  & > div {
    background: #FFFFFF;
    width: max-content;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    padding: 1rem 1rem;
    border-radius: 1rem;
    transform: translateY(70%);
    box-shadow: 0 0 5px -2px #000000;
    transition: 0.2s ease-out;

    span {
      display: flex;
      justify-content: center;
      width: 2ch;
      font-size: 1.3rem;
      cursor: default;
    }

    button {
      padding: 0.3rem 0.5rem;
      margin: 0 0.5rem;
      background-color: ghostwhite;
      border-radius: 5px;
      border: 1px solid;
      cursor: pointer;
      user-select: none;

      &:first-child svg {
        transform: rotate(180deg);
      }

      svg path {
        transition: 0.2s ease-out;
      }

      &:hover {
        svg path {
          transform: translateX(20%);
        }
      }

      &:disabled {
        color: #C4C4C4;
        cursor: default;
        pointer-events: none;

        svg path {
          fill: #C4C4C4;
        }
      }
    }
  }
}
