.operators_configs {
  //height: calc(100% - 1.8rem);
  display: flex;
  flex-direction: column;

  &_config {
    //height: 6.25rem;
    margin-left: 1rem;
    margin-right: 1rem;
    background: linear-gradient(180deg, #E1E1E1 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
    border: 1px solid #B0B0B0;

    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
    padding: 0 1rem;
    overflow: hidden;

    &_title {
      user-select: none;
      text-align: center;
      text-transform: uppercase;
      font-size: 2rem;
      cursor: pointer;
      padding: 1rem 0;
    }
  }

  &_sidebar {
    display: flex;
    flex-direction: column;
    padding: 1.2rem;
    background: #F0F0F0;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    overflow-x: hidden;
    overflow-y: scroll;

    & > * {
      margin: 0.2rem 0;
    }

    &_operator {
      display: block;
      cursor: pointer;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.3rem;

      color: #000000;

      &:hover {
        text-decoration: underline;
      }

      &__id {
        font-size: 0.8rem;
        color: slategray;
        font-weight: 600;
      }
    }

    &_casino_list {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin: 0.5rem 0;
      padding: 0.3rem;
      border-radius: 0.2rem;
      background-color: #DEDEDE;

      & > * {
        cursor: pointer;
      }

      &_addButton {
        background-color: #e5e4e2;
        color: #81b4b4;
      }
    }

    &_selected {
      text-decoration: underline;
      color: #3f000f;
    }

    &_casino_list &_selected {
      background-color: #e4d1ea;
    }
  }

  &_body {
    padding: 0 1rem 1rem 0;
    overflow: scroll;

    & > * {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &_form_box {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 0.5rem;
      background: #F0F0F0;
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
      position: relative;
      min-width: 13rem;
      &[data-disabled="true"]::after{
        background: #000000;
        opacity: 0.13;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        pointer-events: none;
      }
      &_title {
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.3rem;
      }
    }

  }
  &_override {
    position: absolute;
    right: 0.5rem;
    z-index: 11;
    display: flex;
    select {
      margin-right: 0.5rem;
    }
  }

  &_grid_in_3_colum {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    .add_checkbox_layout_left {
      margin-left: 0.5rem;
    }
  }
  &_grid_in_2_colum {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    .add_checkbox_layout_left {
      margin-left: 0.5rem;
    }
  }

  .dropdown_layout {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
  }
  .available_bets_row {
    height: 1.5rem;
    .bet_index {
      flex: none;
      width: 6.25rem;
      margin-right: 0.5rem;
      display: flex;
      justify-content: center;
    }
    .euro_button {
      border: none;
      outline: none;
      border-radius: 5px;
      margin: 0 0.5rem;
      padding: 0 0.35rem 0 0.25rem;

      flex: none;
      transition: transform 0.2s;
      &:hover:not(:disabled) {
        transform: scale(1.1);
      }
    }
  }
  .available_bets_bets_block {
    display: grid;
    grid-template-columns: 24.5rem 1fr 1.5rem;
    .available_bets_bets_List {
      width: 100%;
      overflow-x: scroll;
    }
  }
  & .free_bets .available_bets_bets_block {
    grid-template-columns: 9rem 1fr 1.5rem;
  }

  .edit_modal {
    overscroll-behavior: contain;
    overflow: hidden !important;
    .modal_header {
      text-align: center;
      width: 100%;
      margin-bottom: 1rem;
    }
    align-items: flex-start !important;
    gap: 0.3rem;
    .available_bets_bets_block {
      grid-template-columns: 3rem 5.2rem 11fr;
      width: 100%;
      overflow: auto;
      input {
        touch-action: none;
      }
      .available_bets_bets_List {
        max-width: max-content;
      }
      .available_bets_row.removed {
        background: #ff8383;
        input {
          background: #ff8383;
          pointer-events: none;
        }
      }
      .available_bets_row.green {
        background: #a6ff83;
      }
    }
    button {
      height: 1.5rem;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 191, 255, 0.5);
      &:disabled {
        background: rgba(128, 128, 128, 0.8);
      }
    }
    .icon {
      height: 1.4rem;
      font-size: 1rem;
      width: 1.5rem;
      padding: 0;
      margin: 0;
      flex: none;
      margin-right: 0.1rem;
      transition: transform 0.1s;
      &:hover:not(:disabled) {
        transform: scale(1.04);
      }
    }
    .icon__reset {
      font-size: 1.5rem;
    }
  }

  .hidden {
    display: none !important;
  }

  .yellow_border {
    border: 1px solid yellow !important;
  }
  .red_border {
    border: 1px solid red !important;
  }
}

.operators_configs_filter_warning {
  input {
    border: 1px solid yellow !important;
  }
}

.operators_configs_controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
  z-index: 1;
  padding-bottom: 0;
  .filter_title {
    margin-top: 0;
  }
  &_column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &_previous_value {
    height: 1rem;
  }
}
