.transactions_page_body {
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 4rem;

  .transactions {
    & > * {
      margin-bottom: 0.5rem;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .transaction_block_wrapper {
      position: relative;
      margin-right: 1rem;
      margin-left: 1rem;
      overflow: hidden;
      transition: 0.2s;

      .transaction_block {
        position: relative;
        height: rem(100);
        padding: 0.2rem;
        display: grid;
        grid-template-columns: 2fr 2fr 3fr 3fr 3fr 2fr 3fr 1fr 5fr 5fr 3fr 4fr 10rem;
        grid-template-rows: 100%;
        background: linear-gradient(180deg, #E1E1E1 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
        border: 1px solid #B0B0B0;
        cursor: pointer;
        z-index: 1;

        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 rem(5);
          word-break: break-word;
          text-align: center;

          & > a {
            text-decoration: none;
            color: darkslategrey;
          }
        }

        .game_result_box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          iframe {
            pointer-events: none;
          }
        }

        .close_round_button {
          position: absolute;
          width: 100%;
          bottom: 0;
          left: 0;
          transform: translateY(-25%);
          padding: 0.2rem;
          border-radius: 5px;
          font-size: 0.7rem;
          background-color: #DEDEDE;
          transition: 0.2s;

          &:hover {
            background-color: rgba(144, 238, 144, 0.5);
          }
        }
      }

      .transaction_dropdown {
        width: 100%;
        display: flex;
        flex-direction: column;
        background: #EEEEEE;
        border: 1px solid #CACACA;
        box-shadow: 0 0 8px 0 rgba(34, 60, 80, 0.2) inset;
        transform: translateY(-100%);
        transition: 0.2s;
        z-index: 0;

        & > .transaction_dropdown {
          position: relative;
          box-shadow: none;
          border: none;
          margin: 0;
        }

        &_inner {
          position: relative;
          display: flex;
          flex-direction: column;
          z-index: 0;
        }

        &_head, &_body_row {
          display: grid;
          align-items: center;
          justify-items: center;
          grid-template-columns: 3fr 2fr 3fr 3fr 5fr 5fr 5fr 2fr 3fr 2fr 10rem;
          text-align: center;
        }

        &_head {
          padding: 1rem 0.7rem;

          & > span {
            display: flex;
            align-items: center;
            justify-content: center;
            word-break: break-word;
          }
        }

        &_body {
          &_row {
            min-height: 3rem;
            margin: 0.7rem;
            padding: 0.3rem 0;

            & > span {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              word-break: break-word;

              .game_result_box {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                iframe {
                  pointer-events: none;
                }
              }
            }
          }

          &_row_light_up {
            background-color: #DEDEDE;
          }
        }

        &_preloader {
          width: 100%;
          height: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 0;

          .loading_indicator {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }

      .transaction_dropdown_show {
        transform: translateY(0);
        animation: show 0.2s;

        @keyframes show {
          0% {
            transform: translateY(-100%);
          }
          100% {
            transform: translateY(0);
          }
        }
      }

      .transaction_dropdown_hide {
        transform: translateY(-100%);
      }
    }
  }
}
