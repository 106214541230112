.container {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  padding: 4rem;
}

.container h1, .container h3 {
  color: #C4C4C4;
  font-family: 'Ubuntu', 'sans-serif';
}

.container h3 {
  text-align: center;
}
