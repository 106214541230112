* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Ubuntu', 'sans-serif';
  color: rgba(47, 79, 79, 1);
}

*:not(svg text) {
  font-weight: 500;
  font-size: 0.9rem;
}

.app {
  height: 100vh;
  width: 100vw;
}

.page {
  height: 100%;

  &_title {
    font-size: 1.5rem;
    margin-left: 1rem;
  }

  & > * {
    padding-bottom: 1rem;
  }
}

.rounds_page {
  display: grid;
  grid-template-rows: auto 1fr;
}

button {
  outline: none;
  border: none;
  appearance: none;
}

.button {
  outline: none;
  border: none;
  position: relative;
  margin: 0 1rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  background-color: lavender;
  user-select: none;
  transition: 0.3s;
}

.button:hover:not(.super_admin_menu_button, :disabled) {
  padding: 0.5rem 1.5rem;
  margin: 0 0.5rem;
}

.button:disabled {
  background-color: #CACACA;
  cursor: default;
}

.button_search {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
  cursor: pointer;
  user-select: none;

  background: #389FEA;
  color: #FFFFFF;
}

.button_download {
  padding: 0.3rem;
  font-size: 0.6rem;
  font-weight: 700;
  background-color: darkseagreen;
  color: #FFFFFF;
  border-radius: 0.2rem;
  cursor: pointer;
  user-select: none;
}

.button_blue {
  background: #389FEA;
  color: #FFFFFF;
}

.button_light_blue {
  background-color: rgba(0, 191, 255, 0.5);
}

.blue_border {
  border-color: #389FEA !important;
}

.blue_light_border {
  border-color: rgba(0, 191, 255, 0.5) !important;
}

.cursor_pointer {
  cursor: pointer;
}

.super_admin_menu {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  user-select: none;

  &_button {
    text-align: left;
    padding: 0.3rem;
    color: #FFFFFF;
    background: transparent;
  }

  a {
    text-decoration: none;
  }
}

.button_preloader {
  position: absolute;
  top: 0;
  right: 100%;
  height: 1.5rem;
  animation: rotate 0.5s;
  animation-iteration-count: infinite;

  @keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
}

.operation_success {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0;
  left: 100%;
  transform: translate(50%, -50%);
  animation: showSuccess 0.5s;

  @keyframes showSuccess {
    0% {
      opacity: 0;
      transform: translate(50%, -100%);
    }
    100% {
      opacity: 1;
      transform: translate(50%, -50%);
    }
  }
}

input, select {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: 0.3s;
  font-size: 1rem;
  line-height: lh(1.2rem, 1rem);
  background-color: white;
}

input:focus, select:focus {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

input {
  padding: 0 0.3rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.color_grey {
  color: rgba(130, 130, 130, 0.4);
}

.center_text {
  text-align: center;
}

.relative {
  position: relative;
}

.tip {
  color: rgba(130, 130, 130, 0.4);
  margin-bottom: 1rem;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.column {
  flex-direction: column;
}

.pointer {
  cursor: pointer;
}

.loading_indicator {
  width: 100%;
  height: 100%;

  svg {
    transform: rotate(0deg);
    animation: rotatePreloader 0.3s linear;
    animation-iteration-count: infinite;
  }
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 200;

  &_label {
    margin-top: 1rem;
    font-size: 1rem;
    color: white;
  }

  .loading_indicator {
    width: 10rem;
    height: 10rem;

    path {
      fill: #FFFFFF;
    }
  }
}

.game_result_box {
  position: relative;

  .loading_indicator {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}

.game_result_placeholder {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    transform: translateX(-50%);
  }

  span {
    color: rgba(255, 0, 0, 0.5);
  }
}

.corrupted_data_placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no_data_placeholder {
  max-height: rem(350);
  display: block;
  margin: 0 auto;
}

.add_checkbox_layout {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.add_checkbox_layout_additional_info {
  color: gray;
}

.add_checkbox_layout_left {
  margin-left: 1rem;
}

.add_checkbox_layout_right {
  margin-right: 1rem;
}


.dropdown_layout {
  &_title {
    display: flex;
    align-items: center;
    width: max-content;
    user-select: none;
    cursor: pointer;

    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.2rem;

    svg {
      transform: rotate(-90deg);
      margin-right: 0.5rem;
    }
  }

  &_title_open {
    svg {
      transform: rotate(0);
    }
  }

  .dropdown_body {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    margin: 0.5rem 0 0 1rem;
  }
  &__sub {
    margin-left: 2rem;
    .dropdown_layout_title {
      font-size: 0.9rem;
      line-height: 1rem;
    }
  }
}

.react-json-view {
  .copy-to-clipboard-container {
    svg {
      height: 0.8rem !important;
      width: 0.8rem !important;
    }
  }
}

.input_error {
  border: 1px solid rgba(255, 0, 0, 1) !important;
  animation: errorShake 0.1s;
  animation-timing-function: ease;
}

@keyframes rotatePreloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes errorShake {
  0% {
    transform: translateX(0)
  }
  50% {
    transform: translateX(3px)
  }
  75% {
    transform: translateX(-2px)
  }
  100% {
    transform: translateX(0)
  }
}
