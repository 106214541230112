.partners_config {
  height: calc(100% - 1.8rem);
  display: grid;
  grid-template-columns: 16rem 1fr;
  grid-gap: 2rem;
  padding: 1rem;
  overflow: hidden;

  &_sidebar {
    display: flex;
    flex-direction: column;
    padding: 1.2rem;
    background: #F0F0F0;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    overflow-x: hidden;
    overflow-y: scroll;

    & > * {
      margin: 0.2rem 0;
    }

    &_operator {
      display: block;
      cursor: pointer;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.3rem;

      color: #000000;

      &:hover {
        text-decoration: underline;
      }

      &__id {
        font-size: 0.8rem;
        color: slategray;
        font-weight: 600;
      }
    }

    &_casino_list {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin: 0.5rem 0;
      padding: 0.3rem;
      border-radius: 0.2rem;
      background-color: #DEDEDE;

      & > * {
        cursor: pointer;
      }

      &_addButton {
        background-color: #e5e4e2;
        color: #81b4b4;
      }
    }

    &_selected {
      text-decoration: underline;
      color: #3f000f;
    }
    
    &_casino_list &_selected {
      background-color: #e4d1ea;
    }
  }

  &_body {
    padding: 1rem 1rem 1rem 0;
    overflow: scroll;

    & > * {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &_form_box {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: max-content;
      padding: 0.5rem;
      background: #F0F0F0;
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
      position: relative;
      min-width: 13rem;
      &[data-disabled="true"]::after{
        background: #000000;
        opacity: 0.13;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        pointer-events: none;
      }
      &_title {
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.3rem;
      }
    }

  }
  &_override {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 11;
  }
}

.partners_config_controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: fixed;
  right: 2rem;
  top: 2rem;
  z-index: 1;
}
