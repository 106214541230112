.input_form {
  display: flex;
  align-items: center;

  &_title {
    margin-right: 1rem;

    &_messageEvent {
      margin-left: 0.5rem;
      margin-right: 1rem;
      min-width: 8rem;
    }

    &__size_fixed {
      min-width: 12rem;
    }
  }

  &_subtitle {
    min-width: 4rem;
    margin-left: 1rem;
  }

  &_info {
    position: relative;
    margin-left: 0.5rem;

    svg {
      width: 1rem;
      height: 1rem;
    }

    &_block {
      position: absolute;
      top: 0;
      left: 110%;
      max-width: 20rem;
      max-height: 10rem;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0.4rem;
      border-radius: 0.4rem;
      background-color: #FFFFFF;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
      z-index: 12;
      white-space: pre;

      & > span {
        font-size: 1rem;
      }
    }
  }

  &_grid_3_label_left {
    display: grid;
    grid-template-columns: 1fr max-content max-content;
  }

  &_grid_3_label_right {
    display: grid;
    grid-template-columns: max-content max-content max-content;
  }

  input {
    max-width: 6.25rem;
    margin-right: 0.5rem;

  }

  .input_small_width {
    max-width: 4rem;

  }

  .big_input {
    max-width: 33rem;
    width: 33rem;
  }

  .medium_input {
    max-width: 16rem;
    width: 16rem;
  }

  select {
    margin-left: 0.5rem;
  }

  input:disabled,
  select:disabled {
    color: #787878;
    background-color: #DFDFDF;
  }

  .search_input_dropdown {
    position: absolute;
    top: 100%;
    width: 100%;
    height: max-content;
    max-height: rem(500);
    padding: 1rem 0.5rem;
    background-color: white;
    border-radius: 0 0 5px 5px;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
    overflow: auto;
    z-index: 20;

    &_option {
      padding: 0.3rem;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      text-align: left;
      cursor: pointer;
      font-size: 0.75rem;

      &:hover {
        background-color: lavender;
      }
    }
  }

  & .active {
    background-color: lavender;
  }

  &__sub {
    margin-left: 2rem;
  }

  &_textCheckbox {
    padding: 0.1rem 0.3rem;
    border-radius: 5px;
    transition: 0.3s;
    font-size: 1rem;
    background-color: white;
    text-align: center;
    min-width: 90px;
    margin-right: 1rem;
    cursor: pointer;
    position: relative;
  }

  &_textCheckbox:last-of-type {
    margin-right: 0;
  }

  &_textCheckbox[data-select=true] {
    background-color: rgba(0, 191, 255, 0.5);
  }

  &_textCheckbox[data-disabled=true] {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #787878;
      opacity: 0.3;
      border-radius: 5px;
      cursor: auto;
    }
  }

  .input_form_from_info {
    margin-left: 0.5rem;
    color: gray;
  }
}

.input_forms {
  &_checkbox {
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      display: none;
    }

    input + label {
      display: inline-flex;
      align-items: center;
      user-select: none;
    }

    input + label::before {
      content: '';
      display: inline-block;
      width: 1em;
      height: 1em;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid #adb5bd;
      border-radius: 0.2rem;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% 100%;
      transition: 0.2s;
    }

    input:checked + label::before {
      background-image: url('../assets/icons/checkmark.svg');
    }

    input:disabled + label::before {
      opacity: 0.6;
    }
  }
}

.available_bets_row {
  margin-bottom: rem(2);

  & > * {
    display: flex;
    align-items: center;
    white-space: nowrap;
    flex: 1 1;
  }

  &_currency,
  & > *:nth-child(2) {
    flex: 5 1;
  }

  &_currency {
    gap: 0.5rem;
    text-transform: uppercase;
    button {
      cursor: pointer;
      appearance: none;
      outline: none;
      border: none;
    }

    button:disabled {
      cursor: default;
    }

    svg {
      width: rem(12);
      height: rem(12);
      opacity: 0;
      transition: 0.2s;
    }
  }

  &:hover {
    .available_bets_row_currency svg {
      opacity: 1;
    }
  }
}
