.rounds_history {
  height: 100vh;
  height: 100dvh;
  padding: 1rem;

  a span {
    color: revert;
  }

  &__dates_picker {
    margin-bottom: 1rem;
  }
}

.rounds_history_details {
  height: 100vh;
  height: 100dvh;

  iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
}
