.modal_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  opacity: 0;
  transition: 0.3s;

  .modal_container {
    position: relative;
    max-height: 90vh;
    overflow: auto;
    padding: 2rem 0 2rem 2rem;
    border-radius: 10px;
    background-color: white;

    .modal_x_button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 2rem;
      transform: translateX(-50%);
      cursor: pointer;
    }

    .modal {
      width: 40rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding-right: 2rem;
      overflow-x: hidden;
      overflow-y: auto;

      .modal_header {
        font-size: 2rem;
        text-align: center;
      }

      .modal_body {
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;

        &_message {
          height: 100%;
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .form_block {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 1rem;

          &:first-child {
            margin-top: 0;
          }

          label, .filter_title {
            width: 60%;
            color: rgba(47, 79, 79, 0.5);
            text-align: left;
          }

          input, select {
            height: 2rem;
            width: 60%;
          }

          .currency_label {
            position: absolute;
            left: 81%;
            bottom: 0;
            font-size: 1.8rem;
          }

          &_error {
            position: absolute;
            top: 102%;
            left: 20%;
            color: rgba(255, 0, 0, 0.7);
          }
          .filter {
            width: 60%;
            input {
              width: 100%;
            }
            .search_filter_dropdown {
              position: absolute;
              top: 100%;
              width: 100%;
              height: max-content;
              max-height: rem(180);
              padding: 1rem 0.5rem;
              background-color: white;
              border-radius: 0 0 5px 5px;
              box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
              overflow: auto;
              z-index: 20;

              &_option {
                padding: 0.3rem;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                text-align: left;
                cursor: pointer;
                font-size: 0.75rem;
                overflow: hidden;

                &:hover {
                  background-color: lavender;
                }

                span {
                  white-space: nowrap;
                }
              }
            }
          }
        }

        .form_block_checkbox {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-top: 0.8rem;

          label {
            width: max-content;
            margin-right: 1rem;
          }

          input {
            width: max-content;
          }
        }
      }

      .modal_controls {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem 2rem;
      }
    }

    .modal_transaction {
      display: grid;
      grid-template-columns: 100%;
    }

    .preview_full_scale {
      display: grid;
      grid-template-rows: 100%;

      .game_result_box,
      .game_result_placeholder {
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .info_modal_item {
      width: 100%;
      word-break: break-word;
      margin: 1rem auto;

      & > div:first-child {
        text-align: center;
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
      }
    }

    .modal_xs {
      width: 20rem;
      height: 10rem;
    }

    .modal_s {
      height: 15rem;
    }

    .modal_m {
      height: 25rem;
    }

    .modal_l {
      width: 60rem;
      height: 35rem;
    }
    .modal_xxl {
      width: 100rem;
      height: 60rem;
      max-width: 90vw;
      max-height: 80vh;
    }
  }
}

.users_manager {
  &_header {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3rem;

    & > button {
      padding: 0.2rem 0.3rem;
      font-size: 1.2rem;
      transition: 0.4s;
      border-radius: 5px;
      cursor: pointer;
      user-select: none;

      &:hover {
        background-color: lavender;
      }
    }

    .active {
      background-color: lavender;
    }
  }
}

.compare_json {
  &_item {
    width: 100%;
    white-space: break-spaces;
    background-color: whitesmoke;
  }

  &_added {
    background-color: rgba(0, 255, 0, 0.4);
  }

  &_removed {
    background-color: rgba(255, 0, 0, 0.4);
  }

  &_placeholder {
    text-align: center;
    background-color: rgba(196, 196, 196, 0.3);
    font-size: 2rem;
  }
}

.modal_wrapper_visible {
  visibility: visible;
  opacity: 1;

  .modal_container {
    animation: showModalContent 0.3s;

    @keyframes showModalContent {
      0% {
        transform: translateY(- 3rem);
      }
      100% {
        transform: translateY(0);
      }
    }
  }
}

.modal_wrapper_error {
  z-index: 101;
}

.modal_transaction,
.rounds_history {
  .game_result_box,
  .game_result_placeholder {
    display: flex;
    height: 20rem;

    .book_of_bamboo,
    .book_of_eye,
    .book_of_pyramids,
    .fruity_book,
    .jackpotter,
    .jackpotter_halloween,
    .don_papu_sweet_halloween,
    .don_papu_sweet_christmas,
    .don_papu_sweet_branding,
    .witchs_secret,
    .zoome_in_jungles,
    .red_dragon_ways,
    .jokers_coins,
    .scrooge-coins {
      max-width: unset;
      margin: 0 auto;

      &_symbols {
        max-height: unset;
        padding: 0.6rem;
      }
    }

    .christmas_7 {
      max-width: unset;
      margin: 0 auto;

      &_symbols {
        max-height: unset;
        padding: 4% 3%;
      }
    }

    .combo_7,
    .western_zombie,
    .forest_treasures,
    .gods_party,
    .friday_in_vegas {
      max-width: unset;
      margin: 0 auto;

      &_symbols {
        max-height: unset;
      }
    }

    .hot_and_spicy,
    .brazil_farm,
    .anubis_obelisk,
    .troyan_gems,
    .chervona_kalyna,
    .chinese_express,
    .wild_west_girls ,{
      max-width: unset;
      width: max-content;
      margin: 0 auto;

      &_wrapper {
        display: contents;
      }

      &_jackpot_label {
        font-size: 1.7rem;
      }

      &_peppers,
      &_cards,
      &_maces {
        img {
          margin: 0 0.5%;
        }
      }
    }

    .coin svg {
      height: 100%;
    }

    .golden_clover {

      img {
        height: 100%;
        width: 100%;
      }
    }

    .lucky_clover {
      margin: 0 auto;

      svg {
        height: 100%;
        width: 100%;
      }
    }

    .limbo_cat, .fighter, .quantum_x, .need_for_x, .cricket, .when_lambo {
      &_coef {
        font-size: 4.5rem;
      }

      .quantum_x_coef, .need_for_x_coef {
        text-stroke: 2px #7b42ff;
        -webkit-text-stroke: 2px #7b42ff;
      }
    }

    .piggy_tap {
      .piggy_tap_text {
        font-size: 2rem;
        img {
          height: 2rem;
        }
      }
      .piggy_tap_coef {
        font-size: 4.5rem;
      }
    }

    .lucky_tanks {
      &_field {
        img, .lucky_tanks_placeholder {
          max-width: 100%;
          margin: -0.5rem;
        }
      }
    }

    .thimbles {
      &_field {

        img {
          max-width: 6rem;
        }
      }
    }

    .juicy_crush,
    .juicy_crush_halloween,
    .crystal_cascade {
      max-width: unset;
      max-height: unset;
    }

    .royal_kitties,
    .iggy_racing,
    .inca_son {
      max-width: unset;

      &_bonus_symbol_label {
        display: block;
      }
    }

    .happy_cookie,
    .laplandia_train,
    .caucasian_wolf,
    .crown_of_afrodita,
    .snow_giants {
      max-width: 90%;
      margin: 0 auto;

      &_bonus_symbol_label {
        display: block;
      }
    }

    .jokers_coins_megaways {
      &_bonus_symbol_label {
        font-size: 1rem !important;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .modal {
    width: 30rem !important;

    .lucky_tanks {
      &_field {
        width: 80% !important;
      }
    }
  }
}
